<template>
  <v-row class="pb-8">
    <carousel />
    <v-col 
      cols="8"
      sm="8"
      md="9"
      lg="10"
    >
      <session-title
        class="pt-8 px-8 pb-0"
        title="Período de Férias"
      />
    </v-col>
    <v-col
      class="pl-13 pl-sm-0"
      cols="12"
      sm="4"
      md="3"
      lg="2"
      align-self="center"
    >
      <chat-ti-modal-access-vue />
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="vacations"
        hide-default-footer
      />
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs'
import SessionTitle from '../../components/SessionTitle.vue'
import Carousel from '@/components/Carousel'
import { findVacation } from '@/services/vacation-service'
import ChatTiModalAccessVue from '../../components/ModalViews/ChatTiModalAccess.vue'

export default {
  name: 'Vacation',
  components: {
    carousel: Carousel,
    SessionTitle,
    ChatTiModalAccessVue,
    Carousel
  },
  data () {
    return {
      headers: [
        {
          text: 'Tipo',
          align: 'start',
          sortable: false,
          value: 'type'
        },
        {
          text: 'Situação',
          sortable: false,
          value: 'situation'
        },
        {
          text: 'Data Periodo Final',
          sortable: false,
          value: 'endDate'
        },
        {
          text: 'Data de Programação',
          sortable: false,
          value: 'programmingDate'
        },
        {
          text: 'Data de Pagamento',
          sortable: false,
          value: 'paymentDay'
        },
        {
          text: 'Dias de Férias',
          sortable: false,
          value: 'days'
        }
      ],
      vacations: []
    }
  },
  async created () {
    try {
      const data = await findVacation()
      this.vacations = data
      this.vacations.forEach(vacation => {
        vacation.endDate = this.formatDate(vacation.endDate)
        vacation.programmingDate = this.formatDate(vacation.programmingDate)
        vacation.paymentDay = this.formatDate(vacation.paymentDay)
      })
    } catch (error) {
      this.$handleHttpError(error)
    }
  },
  methods: {
    formatDate (date) {
      return date ? dayjs(date).format('DD/MM/YYYY') : 'Não definido'
    }
  }
}
</script>
